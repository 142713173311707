import {database, firestore} from "../../../config/firebase";
import {asyncActionError, asyncActionFinish, asyncActionStart} from "../../async/AsyncActions";
import {getUserId, getUsername} from "../../../utilities/utils";
import {
    ADD_POST,
    DELETE_POST,
    POST_COMMENTS_FETCH_EMPTY,
    POST_COMMENTS_FETCH_FAILURE,
    POST_COMMENTS_FETCH_SUCCESS,
    READ_POST,
    REPLY_FAILED,
    REPLY_SENT,
    RESET_REDUCER_STATE,
    UPDATE_POST
} from "../../../actions/types";
import isNull from 'lodash/isNull';
import pull from 'lodash/pull';
import {toastr} from 'react-redux-toastr';
import {openModal} from "../../../features/modals/modalActions";
import {child, onValue, push, ref, serverTimestamp} from "firebase/database";
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    increment,
    updateDoc
} from "firebase/firestore/lite";
import * as Sentry from "@sentry/node";

export const reply = (text, gif, postId, postAuthorId, parentId = null) => {

    return async (dispatch) => {

        try {
            dispatch(asyncActionStart());

            const replyData = {
                t: text,
                a: getUsername(),
                aId: getUserId(),
                dVC: 0,
                ts: serverTimestamp(),
                pId: parentId,
                rc: 0,
                g: gif
            };

            if (isNull(parentId) && (getUserId() !== postAuthorId)) {
                replyData['n'] = postAuthorId;
            }

            push(child(ref(database, 'comments'), postId), replyData)

            dispatch({type: REPLY_SENT});
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch({type: REPLY_FAILED});
            dispatch(asyncActionError());
            Sentry.captureException(error);
        }
    }
};

export const clearState = () => {
    return {
        type: RESET_REDUCER_STATE
    }
};

export const toggleBookmarkPost = (post, userId = getUserId()) => async (dispatch) => {

    if (Boolean(userId)) {
        let {id: postId, bookmarkers} = post;

        const postRef = doc(collection(firestore, 'posts'), postId);

        if (bookmarkers.includes(userId)) {
            pull(bookmarkers, userId);
            dispatch({type: UPDATE_POST, payload: post});

            toastr.warning('Post Unsaved', 'This has been removed from your bookmarks');
            return updateDoc(postRef, {
                'bookmarkers': arrayRemove(userId)
            });


        } else {
            bookmarkers.push(userId);
            dispatch({type: UPDATE_POST, payload: post});

            toastr.success('Post saved', 'You can review later in the bookmarks section')
            return updateDoc(postRef, {
                'bookmarkers': arrayUnion(userId)
            });

        }
    } else {
        dispatch(openModal('UnauthModal'))
    }
};

export const toggleLikePost = (post, userId = getUserId()) => async (dispatch) => {

    if (Boolean(userId)) {
        let {id: postId, likers, dislikers, diffVoteCnt} = post;
        const postRef = doc(collection(firestore, 'posts'), postId);

        if (likers.includes(userId)) {
            pull(likers, userId);
            dispatch({type: UPDATE_POST, payload: {...post, diffVoteCnt: --diffVoteCnt}});

            return updateDoc(postRef, {
                'diffVoteCnt': increment(-1),
                'hotScore': increment(-0.005),
                'likers': arrayRemove(userId)
            });
        } else {
            if (dislikers.includes(userId)) {
                pull(dislikers, userId);

                dispatch({type: UPDATE_POST, payload: {...post, diffVoteCnt: ++diffVoteCnt}});

                return updateDoc(postRef, {
                    'diffVoteCnt': increment(1),
                    'hotScore': increment(0.005),
                    'likers': arrayRemove(userId)
                });
            } else {
                likers.push(userId);
                dispatch({type: UPDATE_POST, payload: {...post, diffVoteCnt: ++diffVoteCnt}});
                return updateDoc(postRef, {
                    'diffVoteCnt': increment(1),
                    'hotScore': increment(0.005),
                    'likers': arrayUnion(userId)
                });
            }
        }
    } else {
        dispatch(openModal('UnauthModal'))
    }
};

export const toggleDislikePost = (post, userId = getUserId()) => async (dispatch) => {

    if (Boolean(userId)) {
        let {id: postId, likers, dislikers, diffVoteCnt} = post;
        const postRef = doc(collection(firestore, 'posts'), postId);

        if (dislikers.includes(userId)) {
            diffVoteCnt++;
            pull(dislikers, userId);

            dispatch({type: UPDATE_POST, payload: {...post, diffVoteCnt}});

            return updateDoc(postRef, {
                'diffVoteCnt': increment(1),
                'hotScore': increment(0.005),
                'dislikers': arrayRemove(userId)
            });

        } else {
            if (likers.includes(userId)) {
                diffVoteCnt--;
                pull(likers, userId);

                dispatch({type: UPDATE_POST, payload: {...post, diffVoteCnt}});

                return updateDoc(postRef, {
                    'diffVoteCnt': increment(-1),
                    'hotScore': increment(-0.005),
                    'likers': arrayRemove(userId)
                });
            } else {
                diffVoteCnt--;
                dislikers.push(userId);

                dispatch({type: UPDATE_POST, payload: {...post, diffVoteCnt}});

                return updateDoc(postRef, {
                    'diffVoteCnt': increment(-1),
                    'hotScore': increment(-0.005),
                    'dislikers': arrayUnion(userId)
                });
            }
        }
    } else {
        dispatch(openModal('UnauthModal'))
    }
};

export const deletePost = (authorId, postId) => async (dispatch) => {
    if ((getUserId() === authorId) || (getUsername() === 'Admin')) {
        const postRef = doc(collection(firestore, 'posts'), postId);
        deleteDoc(postRef)
            .then(function () {
                console.log("Post document successfully deleted!");
                dispatch({type: DELETE_POST, payload: postId});
                // Toast.show({
                //     text: "Deleted Post",
                //     buttonText: "Okay",
                //     type: "danger"
                // });
            }).catch(function (error) {
            Sentry.captureException(error);
        });

        // Alert.alert('Delete post', 'Are you sure you want to delete post?', [
        //     {
        //         text: 'No', onPress: () => {
        //         }, style: 'cancel'
        //     },
        //     {
        //         text: 'Yes', onPress: async () => {
        //             // let {isInternetReachable} = await NetInfo.fetch();
        //             // if (isInternetReachable) {
        //                 firestore.collection('posts').doc(postId).delete()
        //                     .then(function () {
        //                         console.log("Post document successfully deleted!");
        //                         dispatch({type: DELETE_POST, payload: postId});
        //                         Toast.show({
        //                             text: "Deleted Post",
        //                             buttonText: "Okay",
        //                             type: "danger"
        //                         });
        //                     }).catch(function (error) {
        //                     console.error("Error removing document: ", error);
        //                 });
        //             // } else {
        //             //     showNetworkToast()
        //             // }
        //         }
        //     },
        // ], {cancelable: false});
    }
};

export const getPost = postId => async (dispatch) => {
    dispatch(asyncActionStart());
    const postRef = doc(collection(firestore, 'posts'), postId);
    return getDoc(postRef)
        .then(function (post) {
            if (post.data()) {
                dispatch({type: ADD_POST, payload: post.data(), postId});
                dispatch(asyncActionFinish());
            } else {
                dispatch(asyncActionError());
            }
        })
        .catch(function (error) {
            Sentry.captureException(error);
            dispatch(asyncActionError());
        });
};

export const addPost = (post, postId) => async (dispatch) => {
    dispatch({type: ADD_POST, payload: post, postId: postId});
    return dispatch({type: READ_POST, payload: postId});
};

export const readPost = (postId) => async (dispatch) => {
    return dispatch({type: READ_POST, payload: postId})
}


export const getComments = (postId) => async (dispatch) => {
    let commentRef = child(ref(database, 'comments'), postId)

    onValue(commentRef, (snapshot) => {
        if (snapshot.exists()) {
            dispatch({type: POST_COMMENTS_FETCH_SUCCESS, payload: snapshot.val(), postId: postId});
        } else {
            dispatch({type: POST_COMMENTS_FETCH_EMPTY});
        }
    }, function (error) {
        console.error("The read failed: " + error.code);
        dispatch({type: POST_COMMENTS_FETCH_FAILURE});
        Sentry.captureException(error)
    });
}

export const addPostComment = (postId, values, parentId, postAuthorId) =>
    async (dispatch) => {
        dispatch(asyncActionStart());
        const newComment = {
            t: values.comment,
            a: getUsername(),
            aId: getUserId(),
            dVC: 0,
            ts: serverTimestamp(),
            pId: parentId,
            rc: 0,
            // g: values.gif //TODO giphy at some point
        };

        if (!Boolean(parentId) && (getUserId() !== postAuthorId)) {
            newComment['n'] = postAuthorId;
        }

        try {
            let commentRef = child(ref(database, 'comments'), postId)
            await push(commentRef, JSON.parse(JSON.stringify(newComment)));
            dispatch({type: REPLY_SENT});
            dispatch(asyncActionFinish());
        } catch (error) {
            Sentry.captureException(error);
            toastr.error('Oops', 'Problem adding comment')
        }
    }

export const reportPost = (post) => async (dispatch) => {
    if (Boolean(getUserId())) {
        let {id: postId, isReported} = post;
        toastr.success('Post reported', 'Thanks for reporting this story, we shall review it')
        if (!Boolean(isReported)) {
            dispatch({type: UPDATE_POST, payload: {...post, isReported: true}});
            const postRef = doc(collection(firestore, 'posts'), postId);

            return updateDoc(postRef, {
                'isReported': true,
            });
        }
    } else {
        dispatch(openModal('UnauthModal'))
    }
};